import React, { useState } from 'react';
import Button from '../../components/Button';
import Card from '../../components/Card';
import { Column, Container, Row } from '../../components/Grid';
import HeroArchive from '../../components/HeroArchive';
import Icon from '../../components/Icon';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import Section from '../../components/Section';

const ImmersiveExperiencePage = () => {
  const [showIframe, setShowIframe] = useState(false);

  const handleImageClick = () => {
    setShowIframe(true);
  };
  return (
    <Layout
      className="gamified-learning-page"
      title="Gamified Learning Experience"
    >
      <HeroArchive
        className="hero-archive--immersive"
        banner={true}
        heroBanner="HARVEST: From Seed to Success"
      >
        {!showIframe && (
          <a
            onClick={handleImageClick}
            data-click-type="Module Launch"
            data-click-name="HARVEST From Seed To Success: A 3D Virtual Field Trip"
          >
            <Image
              alt="Click to view interactive content"
              className="interactive-thumbnail"
              filename="gamified-learning-thumbnail.png"
            />
          </a>
        )}
        {showIframe && (
          <iframe
            id="interactiveIframe"
            className="responsive-iframe"
            src="/files/interactives/harvest-heroes/"
            width="100%"
            allowFullScreen
          ></iframe>
        )}
      </HeroArchive>
      <Section className="mb-2">
        <Container>
          <Row>
            <Column medium={8}>
              <h3>About</h3>
              <p>
                In this gamified learning experience, students will take on the
                role of a farmer to learn what it takes to grow seeds into a
                successful harvest. Students will consult ag professionals as
                they analyze a soil sample, choose their crop and fertilizer,
                pick the right moment to harvest, and even take the wheel of the
                combine driver to harvest their crop!
              </p>
              <h3>How to Use the Controls</h3>
              <p>
                To access the{' '}
                <b>
                  <i>HARVEST: From Seed to Success</i>
                </b>{' '}
                experience, it is recommended students navigate using a browser
                on a desktop, Chromebook, or laptop. Internet access is
                required. Students will be able to navigate using WASD keys:{' '}
                <br />
                <br />
                <ul>
                  <li>W = Forwards</li>
                  <li>A = Left</li>
                  <li>S = Backwards</li>
                  <li>D = Right</li>
                </ul>
                Students can also use the computer mouse to look around and view
                the gamified learning experience. Upon entering{' '}
                <b>
                  <i>HARVEST: From Seed to Success</i>
                </b>
                , students will be prompted on how to navigate.
              </p>
              <Image filename="img-immersive-controls.png" />
            </Column>
            <Column medium={4}>
              <Card
                title="Educator Guide"
                image="img-vft3d-harvest-card-educator-guide.jpg"
                headline="HARVEST: From Seed to Success | Educator Guide"
                subhead="Grades 3-12"
                duration="60-75 minutes"
              >
                <p>
                  This educator guide explains the fundamentals of the
                  interactive cooperative harvesting game and provides engaging
                  activities for students aligned to the experience.
                </p>
                <div className="card__button">
                  <Button
                    className="icon--download"
                    target="blank"
                    to="../pdfs/CHS-EdGuide-HARVESTFromSeedToSuccess-V0.3.pdf"
                  >
                    <Icon name="download" marginRight />
                    Educator Guide
                  </Button>
                </div>
              </Card>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="immersive-activity__section">
        <Container>
          <p className="immersive-activity__header">Ready-to-Use Activity</p>
          <Row style={{ boxShadow: '0px 6px 6px 0px #00000040' }}>
            <Column className="immersive-activity__left-column" size={6}>
              <Image filename="img-immersive-RTU-thumb.png" />
            </Column>
            <Column className="immersive-activity__right-column" size={6}>
              <p className="immersive-activity__title">
                From Soil to Harvest: Student Investigation
              </p>
              <p className="immersive-activity__grades">Grades 6-12</p>
              <p className="immersive-activity__description">
                Learn about the science and technology behind successful
                farming.
              </p>
              <Button
                to="https://app.discoveryeducation.com/learn/player/3c3ae5af-aa50-4712-b5f3-c5351fbd5a07"
                target="_blank"
                data-click-type="Module Launch"
                data-click-name="From Soil to Harvest: Student Investigation (6-12)"
              >
                Learn More
                <Icon name="externallink" marginLeft />
              </Button>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ImmersiveExperiencePage;
